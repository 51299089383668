import { Signal, effect, inject } from '@angular/core';
import { Router } from '@angular/router';

export const messagingIntegrationsGuardLogic = (
    isConnected: Signal<boolean>,
    intendedUrl: string,
    redirectUrl: string
) => {
    const router = inject(Router);

    if (isConnected()) {
        return true;
    } else {
        const effRef = effect(() => {
            if (isConnected()) {
                router.navigate([intendedUrl]);
                effRef.destroy();
            }
        });

        router.navigate([redirectUrl]);
        return false;
    }
};
